import React from 'react';
import { LocalStorageValueProvider } from '../LocalStorageValueProvider';
import { TimeRange, isDateTime, toUtc } from '@grafana/data';
import { TimeRangePickerProps, TimeRangePicker, ToolbarButton } from '@grafana/ui';

const LOCAL_STORAGE_KEY = 'grafana.dashboard.timepicker.history';

interface Props extends Omit<TimeRangePickerProps, 'history' | 'theme'> {}

export const TimePickerWithHistory: React.FC<Props> = (props) => {
  const { value, onMoveBackward } = props;
  const hasAbsolute = isDateTime(value.raw.from) || isDateTime(value.raw.to);

  return (
    <LocalStorageValueProvider<TimeRange[]> storageKey={LOCAL_STORAGE_KEY} defaultValue={[]}>
      {(values, onSaveToStore) => {
        return (
          <>
            {!hasAbsolute && (
              <ToolbarButton
                aria-label="Move time range backwards"
                variant="default"
                onClick={onMoveBackward}
                icon="angle-left"
                narrow
                style={{marginRight: "-9px"}}
              />
            )}
            <TimeRangePicker
              {...props}
              history={convertIfJson(values)}
              onChange={(value) => {
                onAppendToHistory(value, values, onSaveToStore);
                props.onChange(value);
              }}
            />
          </>
        );
      }}
    </LocalStorageValueProvider>
  );
};

function convertIfJson(history: TimeRange[]): TimeRange[] {
  return history.map((time) => {
    if (isDateTime(time.from)) {
      return time;
    }

    return {
      from: toUtc(time.from),
      to: toUtc(time.to),
      raw: time.raw,
    };
  });
}

function onAppendToHistory(toAppend: TimeRange, values: TimeRange[], onSaveToStore: (values: TimeRange[]) => void) {
  if (!isAbsolute(toAppend)) {
    return;
  }
  const toStore = limit([toAppend, ...values]);
  onSaveToStore(toStore);
}

function isAbsolute(value: TimeRange): boolean {
  return isDateTime(value.raw.from) || isDateTime(value.raw.to);
}

function limit(value: TimeRange[]): TimeRange[] {
  return value.slice(0, 4);
}
